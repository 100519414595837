var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('b-table',{attrs:{"detailed":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"data":_vm.list,"paginated":"","backend-pagination":"","total":_vm.count},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"centered":"","label":"ID"}},[_vm._v(_vm._s(props.row.id))]),_c('b-table-column',{attrs:{"label":"Name"}},[_c('router-link',{attrs:{"to":{
              name: 'filter_view',
              params: {
                id: props.row.id
              }
            }}},[_vm._v(_vm._s(props.row.name))])],1),_c('b-table-column',{attrs:{"label":"Contract"}},[_c('router-link',{attrs:{"to":{
              name: 'contract_detail',
              query: {
                address: props.row.address
              }
            }}},[_vm._v(" "+_vm._s(props.row.contractName)+" ")]),_c('b-tooltip',{attrs:{"label":"Prototype"}},[(props.row.fromPrototype)?_c('b-icon',{staticStyle:{"margin-left":"5px"},attrs:{"icon":"code-branch","size":"is-small"}}):_vm._e()],1)],1),_c('b-table-column',{attrs:{"label":"Address"}},[_c('span',{staticClass:"is-family-monospace has-text-weight-semibold"},[_vm._v(_vm._s(_vm._f("addr")(_vm._f("toChecksumAddress")(props.row.address))))])]),_c('b-table-column',{attrs:{"centered":"","label":"Operations"}},[_c('div',{staticClass:"buttons has-addons is-centered"},[_c('button',{staticClass:"button is-rounded control is-small",on:{"click":function($event){return _vm.edit(props.row)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"edit"}})],1),_c('button',{staticClass:"button is-rounded control is-small",on:{"click":function($event){return _vm.remove(props.row)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"trash-alt"}})],1)])])]}},{key:"detail",fn:function(props){return [_c('pre',[_vm._v(_vm._s(props.row.abi))])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }