var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"navbar is-dark",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand is-marginless"},[_c('h2',{staticClass:"subtitle has-text-white is-2",staticStyle:{"padding-left":"20px"}},[_vm._v("Inspector")]),_c('a',{staticClass:"navbar-burger",class:{'is-active': _vm.burgerActive},attrs:{"role":"button"},on:{"click":function($event){_vm.burgerActive = !_vm.burgerActive}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"navbar-menu",class:{'is-active': _vm.burgerActive}},[_c('div',{staticStyle:{"width":"40px"}}),_c('div',{staticClass:"navbar-start"},[_vm._l((_vm.routes),function(item,index){return _c('router-link',{key:index,staticClass:"navbar-item",attrs:{"exact":"","to":{name: item.name}}},[_vm._v(_vm._s(item.text))])}),(_vm.views.length)?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_vm._v("Views")]),_c('div',{staticClass:"navbar-dropdown"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":{
                            name: 'filter_mgt'
                        }}},[_vm._v("All")]),_c('hr',{staticClass:"navbar-divider"}),_vm._l((_vm.views),function(view,index){return _c('router-link',{key:index,staticClass:"navbar-item",attrs:{"to":{
                            name: 'filter_view',
                            params: {
                            id: view.id
                            }
                        }}},[_vm._v(_vm._s(view.name))])})],2)]):_vm._e(),(_vm.shortCuts)?_c('router-link',{staticClass:"navbar-item",attrs:{"active-class":"has-background-grey-dark","to":{name: 'short_cuts'}}},[_vm._v("Shortcuts")]):_vm._e()],2),_c('div',{staticClass:"navbar-end",staticStyle:{"padding-right":"20px"}},[(_vm.networks.length === 1)?_c('b-tag',{staticStyle:{"margin":"auto 0px"},attrs:{"size":"is-medium","type":"is-warning"}},[_vm._v(_vm._s(_vm.networks[0].label))]):_vm._e(),(_vm.networks.length > 1)?_c('b-dropdown',{staticStyle:{"vertical-align":"top"},attrs:{"size":"sm","text":_vm.network,"toggle-class":"py-0 px-1"}},[_c('template',{slot:"trigger"},[_c('b-button',{staticClass:"navbar-item",attrs:{"type":"is-dark","label":_vm.network,"icon-right":"caret-down"}})],1),_vm._l((_vm.switchableNetworks),function(n,i){return _c('b-dropdown-item',{key:i,attrs:{"value":n.name},on:{"click":function($event){return _vm.onChange(n.name)}}},[_vm._v(_vm._s(n.label))])})],2):_vm._e(),_c('a',{staticClass:"navbar-item",attrs:{"href":"https://github.com/vechain/inspector-app","target":"_blank"}},[_vm._v("GitHub")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }